import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import {
	Root as CheckboxPrimitiveRoot,
	Indicator as CheckboxPrimitiveIndicator,
} from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import { cx } from "../classnames";
import { cva, type VariantProps } from "class-variance-authority";

const checkboxVariants = cva(
	"peer h-4 w-4 shrink-0 rounded-[3px] border focus-visible:outline-none focus-visible:ring-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
	{
		variants: {
			variant: {
				default:
					"border-stroke-secondary data-[state=checked]:bg-text-primary data-[state=checked]:border-text-primary [&>*:first-child]:text-surface-primary",
				graphite:
					"border-slate-7 hover:bg-slate-alpha-3 data-[state=checked]:bg-slate-7 data-[state=checked]:border-slate-7 data-[state=checked]:text-slate-5",
				brand:
					"border-sky-7 hover:bg-sky-alpha-3 data-[state=checked]:bg-sky-7 data-[state=checked]:border-sky-7 data-[state=checked]:text-sky-5",
				purple:
					"border-purple-7 hover:bg-purple-alpha-3 data-[state=checked]:bg-purple-7 data-[state=checked]:border-purple-7 data-[state=checked]:text-purple-5",
				pink: "border-pink-7 hover:bg-pink-alpha-3 data-[state=checked]:bg-pink-7 data-[state=checked]:border-pink-7 data-[state=checked]:text-pink-5",
				red: "border-red-7 hover:bg-red-alpha-3 data-[state=checked]:bg-red-7 data-[state=checked]:border-red-7 data-[state=checked]:text-red-5",
				orange:
					"border-yellow-7 hover:bg-yellow-alpha-3 data-[state=checked]:bg-yellow-7 data-[state=checked]:border-yellow-7 data-[state=checked]:text-yellow-5",
				green:
					"border-green-7 hover:bg-green-alpha-3 data-[state=checked]:bg-green-7 data-[state=checked]:border-green-7 data-[state=checked]:text-green-5",
			},
			size: {
				sm: "h-3 w-3",
				md: "h-4 w-4",
				lg: "h-5 w-5",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "md",
		},
	},
);

interface CheckboxProps
	extends ComponentPropsWithoutRef<typeof CheckboxPrimitiveRoot>,
		VariantProps<typeof checkboxVariants> {}

const Checkbox = forwardRef<
	ElementRef<typeof CheckboxPrimitiveRoot>,
	CheckboxProps
>(({ className, variant, size, ...props }, ref) => (
	<CheckboxPrimitiveRoot
		ref={ref}
		className={cx(checkboxVariants({ variant, size }), className)}
		{...props}
	>
		<CheckboxPrimitiveIndicator className="text-surface-secondary flex items-center justify-center">
			<Check strokeWidth={2.5} className="h-3 w-3" />
		</CheckboxPrimitiveIndicator>
	</CheckboxPrimitiveRoot>
));

Checkbox.displayName = CheckboxPrimitiveRoot.displayName;

export { Checkbox, checkboxVariants };
