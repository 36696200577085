import { forwardRef } from "react";
import {
	Root as HoverCardPrimitiveRoot,
	Trigger as HoverCardPrimitiveTrigger,
	Content as HoverCardPrimitiveContent,
} from "@radix-ui/react-hover-card";
import { cx } from "../classnames";

const HoverCard = HoverCardPrimitiveRoot;

const HoverCardTrigger = HoverCardPrimitiveTrigger;

const HoverCardContent = forwardRef<
	React.ElementRef<typeof HoverCardPrimitiveContent>,
	React.ComponentPropsWithoutRef<typeof HoverCardPrimitiveContent>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
	<HoverCardPrimitiveContent
		ref={ref}
		align={align}
		sideOffset={sideOffset}
		className={cx(
			"bg-surface-secondary text-text-primary data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 border-stroke-secondary z-50 w-64 origin-[--radix-hover-card-content-transform-origin] rounded-md border p-4 shadow-xl outline-none",
			className,
		)}
		{...props}
	/>
));
HoverCardContent.displayName = HoverCardPrimitiveContent.displayName;

export { HoverCard, HoverCardTrigger, HoverCardContent };
