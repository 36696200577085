import {
	ComponentPropsWithoutRef,
	ElementRef,
	forwardRef,
	HTMLAttributes,
} from "react";
import {
	Root as DialogPrimitiveRoot,
	Trigger as DialogPrimitiveTrigger,
	Portal as DialogPrimitivePortal,
	Close as DialogPrimitiveClose,
	Overlay as DialogPrimitiveOverlay,
	Content as DialogPrimitiveContent,
	Title as DialogPrimitiveTitle,
	Description as DialogPrimitiveDescription,
} from "@radix-ui/react-dialog";
import { X } from "lucide-react";
import { cx } from "../classnames";

const Modal = DialogPrimitiveRoot;

const ModalTrigger = DialogPrimitiveTrigger;

const ModalPortal = DialogPrimitivePortal;

const ModalClose = DialogPrimitiveClose;

const ModalOverlay = forwardRef<
	ElementRef<typeof DialogPrimitiveOverlay>,
	ComponentPropsWithoutRef<typeof DialogPrimitiveOverlay>
>(({ className, ...props }, ref) => (
	<DialogPrimitiveOverlay
		ref={ref}
		className={cx(
			"data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/50",
			className,
		)}
		{...props}
	/>
));
ModalOverlay.displayName = DialogPrimitiveOverlay.displayName;

const ModalContent = forwardRef<
	ElementRef<typeof DialogPrimitiveContent>,
	ComponentPropsWithoutRef<typeof DialogPrimitiveContent>
>(({ className, children, ...props }, ref) => (
	<ModalPortal>
		<ModalOverlay />
		<DialogPrimitiveContent
			ref={ref}
			className={cx(
				"bg-surface-primary data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] border-stroke-tertiary fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] border shadow-lg duration-200 sm:rounded-lg",
				className,
			)}
			{...props}
		>
			{children}
			<DialogPrimitiveClose className="text-text-tertiary hover:text-text-secondary absolute right-4 top-4 rounded-md outline-none transition-all disabled:pointer-events-none">
				<X className="h-4 w-4" />
				<span className="sr-only">Close</span>
			</DialogPrimitiveClose>
		</DialogPrimitiveContent>
	</ModalPortal>
));
ModalContent.displayName = DialogPrimitiveContent.displayName;

const ModalHeader = ({
	className,
	...props
}: HTMLAttributes<HTMLDivElement>) => (
	<div
		className={cx(
			"border-stroke-tertiary flex flex-col gap-1 border-b p-4 text-center sm:text-left",
			className,
		)}
		{...props}
	/>
);
ModalHeader.displayName = "ModalHeader";

const ModalFooter = ({
	className,
	...props
}: HTMLAttributes<HTMLDivElement>) => (
	<div
		className={cx(
			"border-stroke-tertiary flex flex-col-reverse gap-2 border-t p-4 sm:flex-row sm:justify-end",
			className,
		)}
		{...props}
	/>
);
ModalFooter.displayName = "ModalFooter";

const ModalTitle = forwardRef<
	ElementRef<typeof DialogPrimitiveTitle>,
	ComponentPropsWithoutRef<typeof DialogPrimitiveTitle>
>(({ className, ...props }, ref) => (
	<DialogPrimitiveTitle
		ref={ref}
		className={cx("font-repro text-text-primary text-xl", className)}
		{...props}
	/>
));
ModalTitle.displayName = DialogPrimitiveTitle.displayName;

const ModalDescription = forwardRef<
	ElementRef<typeof DialogPrimitiveDescription>,
	ComponentPropsWithoutRef<typeof DialogPrimitiveDescription>
>(({ className, ...props }, ref) => (
	<DialogPrimitiveDescription
		ref={ref}
		className={cx(
			"text-paragraph-sm font-repro text-text-secondary mr-4 font-[350]",
			className,
		)}
		{...props}
	/>
));
ModalDescription.displayName = DialogPrimitiveDescription.displayName;

export {
	Modal,
	ModalPortal,
	ModalOverlay,
	ModalTrigger,
	ModalClose,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalTitle,
	ModalDescription,
};
