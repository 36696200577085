import { forwardRef, InputHTMLAttributes, useState } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cx } from "../classnames";
import { AnimatePresence, motion } from "framer-motion";

const inputVariants = cva(
	"placeholder:text-text-placeholder font-repro flex w-full rounded-md bg-transparent transition-colors disabled:cursor-not-allowed disabled:opacity-50",
	{
		variants: {
			variant: {
				// Default inputs render a slight corner radius and border. For most of the product, these inputs should be used.
				default:
					"border-stroke-tertiary focus-visible:ring-ring border focus-visible:outline-none focus-visible:ring-1",
				// Ghost inputs do not have borders or background. These inputs should be used more sparingly but can work well as editable titles, etc.
				ghost: "border-none outline:none focus-visible:ring-0",
			},
			inputSize: {
				default: "h-9 px-3 py-1 text-paragraph-sm",
				lg: "h-9 p-1 text-paragraph-xl font-medium",
			},
		},
		defaultVariants: {
			variant: "default",
			inputSize: "default",
		},
	},
);

/**
 * Input
 * - Generic input component. An optional suggestions element can be passed. If this element is provided, we will render the component
 * 	 below the input when it is focused. Actions from suggestions can be handled by the parent component.
 */
interface InputProps
	extends InputHTMLAttributes<HTMLInputElement>,
		VariantProps<typeof inputVariants> {
	suggestions?: JSX.Element;
	asChild?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
	({ suggestions, className, variant, inputSize, ...props }, ref) => {
		const [focused, setFocused] = useState<boolean>(false);

		return (
			<div className="relative">
				<input
					className={cx(inputVariants({ variant, inputSize, className }))}
					ref={ref}
					{...props}
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
				/>

				<AnimatePresence>
					{focused && suggestions !== undefined ? (
						<motion.div
							initial={{ opacity: 0, scale: 0.95 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0, scale: 0.95 }}
							transition={{ duration: 0.15 }}
						>
							{suggestions}
						</motion.div>
					) : null}
				</AnimatePresence>
			</div>
		);
	},
);
Input.displayName = "Input";

export { Input };
