import { ComponentProps, forwardRef } from "react";
import { cx } from "../classnames";

/**
 * Textarea
 * - A multi line input component to be used either within forms or independently. The consumer can define
 * 	 (number of rows) how large this text element should be.
 */
const Textarea = forwardRef<HTMLTextAreaElement, ComponentProps<"textarea">>(
	({ className, ...props }, ref) => {
		return (
			<textarea
				className={cx(
					"placeholder:text-text-placeholder font-repro focus-visible:ring-ring border-stroke-tertiary text-text-priamry flex min-h-[60px] w-full rounded-md border bg-transparent px-3 py-2 text-sm font-[350] leading-relaxed focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
					className,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);
Textarea.displayName = "Textarea";

export { Textarea };
