import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { Root as LabelPrimitiveRoot } from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import { cx } from "../classnames";

/**
 * Label
 * - A basic label component. Tyically used within the FormLabel component but can also be used independently.
 */
const labelVariants = cva(
	"text-xs font-repro text-text-secondary leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
);

const Label = forwardRef<
	ElementRef<typeof LabelPrimitiveRoot>,
	ComponentPropsWithoutRef<typeof LabelPrimitiveRoot> &
		VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
	<LabelPrimitiveRoot
		ref={ref}
		className={cx(labelVariants(), className)}
		{...props}
	/>
));
Label.displayName = LabelPrimitiveRoot.displayName;

export { Label };
