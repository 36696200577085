import { HTMLAttributes, useState } from "react";
import { cx } from "../classnames";
import { Input } from "./Input";
import { Check, Plus, Search } from "lucide-react";
import {
	Popover,
	PopoverAnchor,
	PopoverContent,
	PopoverTrigger,
} from "./Popover";
import { ScrollArea } from "./ScrollArea";

export interface MultiselectOption {
	id: string;
	label: string;
	leadingElement?: JSX.Element;
	trailingElement?: JSX.Element;
}

interface MultiselectProps extends HTMLAttributes<HTMLDivElement> {
	label: string;
	options: MultiselectOption[];
	selectedOptions: MultiselectOption[];
	onSelectOption: (options: MultiselectOption[]) => void;
	onCreateOption?: (label: string) => void;
	side?: "top" | "left" | "bottom" | "right";
	disableSearch?: boolean;
}

const Multiselect = ({
	label,
	options,
	selectedOptions,
	onSelectOption,
	onCreateOption,
	side,
	disableSearch,
	children,
}: MultiselectProps) => {
	const [search, setSearch] = useState<string>("");

	const handleSelect = (option: MultiselectOption) => {
		if (
			selectedOptions?.find(
				(selectedOption: MultiselectOption) => option.id === selectedOption.id,
			)
		) {
			onSelectOption(
				selectedOptions.filter(
					(selectedOption: MultiselectOption) =>
						option.id !== selectedOption.id,
				),
			);
		} else {
			onSelectOption([...(selectedOptions ?? []), option]);
		}
	};

	return (
		<Popover modal={false}>
			<PopoverAnchor className="h-full w-full" />
			<PopoverTrigger asChild>{children}</PopoverTrigger>
			<PopoverContent
				side={side ?? "bottom"}
				align="start"
				className={cx(
					"max-w-56 overflow-clip p-0",
					(!side || side === "bottom") && "mt-10",
					side === "top" && "mb-10",
				)}
			>
				{!disableSearch ? (
					<div className="border-stroke-tertiary bg-surface-secondary sticky top-0 border-b">
						<Search className="text-text-tertiary absolute left-2.5 top-2.5 h-4 w-4" />
						<Input
							variant="ghost"
							placeholder={label}
							className="pl-[34px]"
							autoFocus
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
								e.stopPropagation();
							}}
						/>
					</div>
				) : null}

				<ScrollArea className="flex max-h-64 flex-col overflow-x-clip p-1">
					{onCreateOption &&
					search !== "" &&
					!options.some(
						(option) => option.label.toLowerCase() === search.toLowerCase(),
					) ? (
						<div
							className="hover:bg-surface-hover-1 flex cursor-pointer select-none flex-row items-center gap-1 rounded-md px-1.5 py-1.5"
							onClick={() => {
								onCreateOption(search);
								setSearch("");
							}}
						>
							<Plus className="text-text-tertiary h-4 w-4" />
							<div className="font-repro text-text-secondary text-sm font-[350]">
								Create "{search}"
							</div>
						</div>
					) : null}

					{options
						.filter((option: MultiselectOption) =>
							option.label.toLowerCase().includes(search.toLowerCase()),
						)
						.map((option: MultiselectOption) => {
							const isChecked = !!selectedOptions?.find(
								(selectedOption: MultiselectOption) =>
									option.id === selectedOption.id,
							);

							return (
								<div
									className="hover:bg-surface-hover-1 flex cursor-pointer select-none flex-row items-center gap-2 rounded-md px-[7px] py-1.5"
									onClick={() => handleSelect(option)}
								>
									{option.leadingElement}

									<div className="font-repro text-text-primary line-clamp-1 overflow-ellipsis break-all text-sm font-[350]">
										{option.label}
									</div>

									{option.trailingElement}

									<div className="ml-auto">
										{isChecked ? (
											<Check className="text-text-secondary h-4 w-4" />
										) : null}
									</div>
								</div>
							);
						})}

					{!onCreateOption &&
					options.filter((option: MultiselectOption) =>
						option.label.toLowerCase().includes(search.toLowerCase()),
					).length === 0 ? (
						<div className="font-repro text-text-tertiary truncate p-2 text-sm font-[350]">
							No items found
						</div>
					) : null}
				</ScrollArea>
			</PopoverContent>
		</Popover>
	);
};

export { Multiselect };
