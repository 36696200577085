import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cx } from "../classnames";

const buttonVariants = cva(
	"font-repro font-[375] inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full text-sm transition-colors disabled:pointer-events-none disabled:opacity-50 focus-visible:outline-none focus-visible:ring-none",
	{
		variants: {
			variant: {
				/**
				 * General Buttons
				 */

				// Primary buttons render the brand colours and are used for primary actions. This button type should be used sparingly.
				primary:
					"text-sky-1 bg-sky-7 border border-transparent hover:bg-sky-8 font-[400]",
				// Secondary buttons render muted backgrounds and are used for secondary actions.
				secondary:
					"text-text-primary bg-surface-tertiary hover:bg-surface-hover-1 hover:text-text-primary",
				// Outline buttons will only render a border, this offers visual consistency with input components which also exclusively render borders.
				outline:
					"text-text-secondary bg-transparent border border-stroke-tertiary hover:border-stroke-primary",
				// Ghost buttons do not render a background until hovered, used for tertiary actions that should not be prominent on the screen.
				ghost:
					"text-text-tertiary bg-transparent hover:bg-surface-tertiary hover:text-text-primary",

				/**
				 * Action Buttons - Used to highlight constructive or destructive actions with bold colours.
				 */

				// Constructive buttons are used for actions that will create content. Note that we do not always need a constructive button when creating content.
				constructive:
					"border border-dashed border-stroke-tertiary text-text-secondary bg-transparent hover:border-stroke-primary",
				// Destructive buttons are used for actions that will delete content or cause irreversible actions.
				destructive:
					"text-text-primary bg-red-7 border border-transparent hover:bg-red-8",
			},
			size: {
				default: "h-9 w-fit px-3",
				large: "h-10 w-fit px-4",
				// Render a perfect square icon (typically we render 4 x 4 icons, however this size can be manipulated via className if required).
				icon: "h-9 w-9 min-h-9 min-w-9 max-h-9 max-w-9",
			},
		},
		defaultVariants: {
			variant: "primary",
			size: "default",
		},
	},
);

/**
 * Button
 * - Used for rendering a simple button. Can be used independently, or passed into composite
 * 	 components such as Modal, Dropdown, or Sheet triggers.
 */
export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cx(buttonVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			/>
		);
	},
);
Button.displayName = "Button";

export { Button, buttonVariants };
