import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { Root as SeparatorPrimitiveRoot } from "@radix-ui/react-separator";
import { cx } from "../classnames";

/**
 * Separator
 * - A simple seperator that renders a line either horizontally or vertically. The line colour can be overriden with a className.
 */
const Separator = forwardRef<
	ElementRef<typeof SeparatorPrimitiveRoot>,
	ComponentPropsWithoutRef<typeof SeparatorPrimitiveRoot>
>(
	(
		{ className, orientation = "horizontal", decorative = true, ...props },
		ref,
	) => (
		<SeparatorPrimitiveRoot
			ref={ref}
			decorative={decorative}
			orientation={orientation}
			className={cx(
				"bg-stroke-tertiary shrink-0",
				orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
				className,
			)}
			{...props}
		/>
	),
);
Separator.displayName = SeparatorPrimitiveRoot.displayName;

export { Separator };
