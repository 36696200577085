import { forwardRef } from "react";
import {
	Root as TabsPrimitiveRoot,
	List as TabsPrimitiveList,
	Trigger as TabsPrimitiveTrigger,
	Content as TabsPrimitiveContent,
} from "@radix-ui/react-tabs";
import { cx } from "../classnames";

const Tabs = TabsPrimitiveRoot;

const TabsList = forwardRef<
	React.ElementRef<typeof TabsPrimitiveList>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitiveList>
>(({ className, ...props }, ref) => (
	<TabsPrimitiveList
		ref={ref}
		className={cx(
			"bg-slate-alpha-1 text-text-tertiary inline-flex items-center justify-center rounded-lg p-1",
			className,
		)}
		{...props}
	/>
));
TabsList.displayName = TabsPrimitiveList.displayName;

const TabsTrigger = forwardRef<
	React.ElementRef<typeof TabsPrimitiveTrigger>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitiveTrigger>
>(({ className, ...props }, ref) => (
	<TabsPrimitiveTrigger
		ref={ref}
		className={cx(
			"font-repro text-paragraph-sm ring-offset-background focus-visible:ring-ring data-[state=active]:bg-slate-alpha-1 data-[state=active]:text-text-primary inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1.5 text-sm font-[375] transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:shadow",
			className,
		)}
		{...props}
	/>
));
TabsTrigger.displayName = TabsPrimitiveTrigger.displayName;

const TabsContent = forwardRef<
	React.ElementRef<typeof TabsPrimitiveContent>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitiveContent>
>(({ className, ...props }, ref) => (
	<TabsPrimitiveContent
		ref={ref}
		className={cx(
			"ring-offset-background focus-visible:ring-ring mt-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
			className,
		)}
		{...props}
	/>
));
TabsContent.displayName = TabsPrimitiveContent.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
