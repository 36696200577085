export enum Color {
	graphite = "graphite",
	red = "red",
	orange = "orange",
	green = "green",
	brand = "brand",
	purple = "purple",
	pink = "pink",
}

export const ColorVariables: Record<Color, string> = {
	graphite: "text-tertiary",
	red: "red-8",
	orange: "yellow-7",
	green: "green-8",
	brand: "brand",
	purple: "purple-8",
	pink: "pink-8",
};

export const BgColorVariables: Record<Color, string> = {
	graphite: "slate-5",
	red: "red-6",
	orange: "yellow-6",
	green: "green-6",
	brand: "sky-6",
	purple: "purple-6",
	pink: "pink-6",
};
