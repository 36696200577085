import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "./Button";
import { cx } from "../classnames";
import { Color, ColorVariables } from "../utils";

/**
 * RadialColorPicker
 * - A colour selector that expands radially around a single point.
 */
interface RadialColorPickerProps {
	selectedColour: Color;
	onSelect: (color: Color) => void;
	buttonClassName?: string;
}

export const RadialColorPicker = ({
	selectedColour,
	onSelect,
	buttonClassName,
}: RadialColorPickerProps) => {
	const [showWheel, setShowWheel] = useState<boolean>(false);

	return (
		<div className="relative h-8 w-8" onMouseLeave={() => setShowWheel(false)}>
			<AnimatePresence>
				{showWheel && (
					<motion.div
						initial={{ scale: 0.5 }}
						animate={{ scale: 1 }}
						exit={{ scale: 0 }}
						className={cx(
							"absolute inset-0 flex items-center justify-center",
							showWheel ? "z-40" : "z-10",
						)}
					>
						{Object.keys(ColorVariables).map((color, index) => {
							const angle =
								(360 / Object.entries(ColorVariables).length) * index;
							return (
								<div
									key={index}
									className={`absolute -left-1.5 -top-2 h-12 w-12 cursor-pointer rounded-full transition-transform bg-${ColorVariables[color as Color]} opacity-50 hover:scale-125 hover:opacity-100`}
									style={{
										rotate: `${angle}deg`,
										clipPath: "polygon(50% 50%, 100% 0%, 100% 50%)",
										transformOrigin: "50% 50%",
									}}
									onClick={() => {
										onSelect(color as Color);
										setShowWheel(false);
									}}
								/>
							);
						})}
					</motion.div>
				)}
			</AnimatePresence>

			<Button
				variant="outline"
				size="icon"
				type="button"
				onClick={() => setShowWheel(true)}
				className={cx(
					"bg-surface-primary absolute left-1.5 top-1.5 z-50 h-6 min-h-6 w-6 min-w-6 transition-all",
					showWheel && "left-2.5 top-2.5 h-4 min-h-4 w-4 min-w-4",
					buttonClassName,
				)}
			>
				<div
					className={cx(
						`bg-${ColorVariables[selectedColour]} h-3 w-3 rounded-full transition-all`,
						showWheel && "h-2 w-2",
					)}
				/>
			</Button>
		</div>
	);
};
