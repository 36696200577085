import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { cx } from "../classnames";
import {
	Root as AvatarPrimitiveRoot,
	Image as AvatarPrimitiveImage,
	Fallback as AvatarPrimitiveFallback,
} from "@radix-ui/react-avatar";

/**
 * Avatar
 * - Anchor component to contain AvatarImage and AvatarFallback.
 */
const Avatar = forwardRef<
	ElementRef<typeof AvatarPrimitiveRoot>,
	ComponentPropsWithoutRef<typeof AvatarPrimitiveRoot>
>(({ className, ...props }, ref) => (
	<AvatarPrimitiveRoot
		ref={ref}
		className={cx(
			"relative flex h-6 w-6 shrink-0 overflow-hidden rounded-full hover:outline-none focus:outline-none focus-visible:outline-none",
			className,
		)}
		{...props}
	/>
));
Avatar.displayName = AvatarPrimitiveRoot.displayName;

/**
 * AvatarImage
 * - Render an Avatar image. The image will be scaled to cover the entire image component without breaking aspect ratio.
 */
const AvatarImage = forwardRef<
	ElementRef<typeof AvatarPrimitiveImage>,
	ComponentPropsWithoutRef<typeof AvatarPrimitiveImage>
>(({ className, ...props }, ref) => (
	<AvatarPrimitiveImage
		ref={ref}
		className={cx(
			"h-full w-full object-cover hover:outline-none focus:outline-none focus-visible:outline-none",
			className,
		)}
		{...props}
	/>
));
AvatarImage.displayName = AvatarPrimitiveImage.displayName;

/**
 * AvatarFallback
 * - If the provided image cannot be rendered we fallback to this component.
 */
const AvatarFallback = forwardRef<
	ElementRef<typeof AvatarPrimitiveFallback>,
	ComponentPropsWithoutRef<typeof AvatarPrimitiveFallback>
>(({ className, ...props }, ref) => (
	<AvatarPrimitiveFallback
		ref={ref}
		className={cx(
			"bg-surface-tertiary text-text-tertiary flex h-full w-full items-center justify-center rounded-full hover:outline-none focus:outline-none focus-visible:outline-none",
			className,
		)}
		{...props}
	/>
));
AvatarFallback.displayName = AvatarPrimitiveFallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
