import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import {
	Root as ScrollAreaPrimitiveRoot,
	Viewport as ScrollAreaPrimitiveViewport,
	Corner as ScrollAreaPrimitiveCorner,
	ScrollAreaScrollbar as ScrollAreaPrimitiveScrollAreaScrollbar,
	ScrollAreaThumb as ScrollAreaPrimitiveScrollAreaThumb,
} from "@radix-ui/react-scroll-area";
import { cx } from "../classnames";

const ScrollArea = forwardRef<
	ElementRef<typeof ScrollAreaPrimitiveRoot>,
	ComponentPropsWithoutRef<typeof ScrollAreaPrimitiveRoot>
>(({ className, children, ...props }, ref) => (
	<ScrollAreaPrimitiveRoot
		ref={ref}
		className={cx("relative", className)}
		{...props}
	>
		<ScrollAreaPrimitiveViewport className="h-full w-full rounded-[inherit]">
			{children}
		</ScrollAreaPrimitiveViewport>
		<ScrollBar />
		<ScrollAreaPrimitiveCorner />
	</ScrollAreaPrimitiveRoot>
));
ScrollArea.displayName = ScrollAreaPrimitiveRoot.displayName;

const ScrollBar = forwardRef<
	ElementRef<typeof ScrollAreaPrimitiveScrollAreaScrollbar>,
	ComponentPropsWithoutRef<typeof ScrollAreaPrimitiveScrollAreaScrollbar>
>(({ className, orientation = "vertical", ...props }, ref) => (
	<ScrollAreaPrimitiveScrollAreaScrollbar
		ref={ref}
		orientation={orientation}
		className={cx(
			"flex touch-none select-none transition-colors",
			orientation === "vertical" &&
				"h-full w-2.5 border-l border-l-transparent p-[1px]",
			orientation === "horizontal" &&
				"h-2.5 flex-col border-t border-t-transparent p-[1px]",
			className,
		)}
		{...props}
	>
		<ScrollAreaPrimitiveScrollAreaThumb className="bg-surface-hover-1 hover:bg-surface-hover-2 relative z-20 flex-1 rounded-full" />
	</ScrollAreaPrimitiveScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitiveScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
