import { HTMLAttributes } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cx } from "../classnames";

/**
 * Defines a set of Badge variants. We should continously update these variants as new use cases arise
 * instead of adding custom classNames within our business logic.
 */
const badgeVariants = cva(
	"inline-flex whitespace-nowrap items-center font-repro transition-all w-fit flex flex-row gap-2 transition-all relative",
	{
		variants: {
			variant: {
				// Default badges should be used in most cases for simple text badges surrounded by a border.
				default:
					"border border-stroke-tertiary bg-transparent text-text-primary shadow",
				// Default badges should be used in most cases for simple text badges surrounded by a border.
				secondary:
					"border border-transparent bg-surface-tertiary text-text-secondary shadow",
				// Constructive badges are used when the badge can be used to create something, as a result they have dashed borders and a pointer cursor.
				constructive:
					"border border-dashed border-stroke-tertiary text-text-secondary bg-transparent hover:border-stroke-primary cursor-pointer",
			},
			size: {
				default: "px-2.5 py-1.5 text-xs rounded-full",
				small: "px-1 text-[11px] rounded-md",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);

/**
 * Badge
 * - Render a small content badge. This has been extended for specific label use cases in BadgeLabel. We should ensure we never restrict the content
 * 	 of the badge and instead allow any children to be passed in.
 */
export interface BadgeProps
	extends HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
	return (
		<div
			className={cx(badgeVariants({ variant, size }), className)}
			{...props}
		/>
	);
}

export { Badge, badgeVariants };
