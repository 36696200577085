import { HTMLAttributes } from "react";
import { cx } from "../classnames";
import { Badge } from "./Badge";
import { LucideIcon } from "lucide-react";
import { Color, ColorVariables } from "../utils/colors";

/**
 * BadgeLabel
 * - Render a label. This component is an extension of the Badge component and handles our very common use case of
 * 	 having a small Color indicator next to the label content. We can also provide an optional "action" Icon which
 * 	 will be visible on hover (the consumer is responsible for defining the onClick behaviour when triggered).
 */
export interface BadgeLabelProps extends HTMLAttributes<HTMLDivElement> {
	color: Color;
	Icon?: LucideIcon;
}

function BadgeLabel({
	color,
	Icon,
	children,
	className,
	...props
}: BadgeLabelProps) {
	return (
		<Badge
			variant="default"
			className={cx(
				"group/tag text-paragraph-sm font-repro text-text-primary relative cursor-pointer select-none gap-0 overflow-clip whitespace-nowrap pl-7 pr-3 transition-all",
				Icon && "hover:pl-3 hover:pr-7",
				className,
			)}
			{...props}
		>
			{/* Render the color indicator, we rely on the Color utils here to map string representations of color (eg. red) to variable representations (eg. red-7) */}
			<div
				className={cx(
					`absolute left-3 h-2 w-2 rounded-full transition-all bg-${ColorVariables[color]}`,
					Icon && "group-hover/tag:opacity-0",
				)}
			/>

			{/* If an icon is provided render it here, note that the icon will not be rendered (opacity) until the group/tag is hovered */}
			{Icon ? (
				<Icon className="text-text-tertiary absolute right-2 h-4 w-4 opacity-0 transition-all group-hover/tag:opacity-100" />
			) : null}

			{/* Pass through children content to Badge */}
			{children}
		</Badge>
	);
}

export { BadgeLabel };
