// Tremor LineChart [v0.3.2]
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	CartesianGrid,
	Dot,
	Label,
	Line,
	Legend as RechartsLegend,
	LineChart as RechartsLineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";

import {
	AvailableChartColors,
	AvailableChartColorsKeys,
	constructCategoryColors,
	getColorClassName,
	getYAxisDomain,
	hasOnlyOneValueForKey,
} from "../lib/chartUtils";
import { useOnWindowResize } from "../hooks/useOnWindowResize";
import { cx } from "../lib/utils";
import { ArrowLeft, ArrowRight } from "lucide-react";
import {
	ComponentType,
	Dispatch,
	ElementType,
	forwardRef,
	Fragment,
	HTMLAttributes,
	OlHTMLAttributes,
	SetStateAction,
	useCallback,
	useEffect,
} from "react";
import { useRef } from "react";
import { useState } from "react";

//#region Legend

interface LegendItemProps {
	name: string;
	color: AvailableChartColorsKeys;
	onClick?: (name: string, color: AvailableChartColorsKeys) => void;
	activeLegend?: string;
}

const LegendItem = ({
	name,
	color,
	onClick,
	activeLegend,
}: LegendItemProps) => {
	const hasOnValueChange = !!onClick;
	return (
		<li
			className={cx(
				// base
				"group inline-flex flex-nowrap items-center gap-2 whitespace-nowrap rounded transition",
				hasOnValueChange ? "cursor-pointer" : "cursor-default",
			)}
			onClick={(e) => {
				e.stopPropagation();
				onClick?.(name, color);
			}}
		>
			<span
				className={cx(
					"h-4 w-4 shrink-0 rounded-[4px]",
					getColorClassName(color, "bg"),
					activeLegend && activeLegend !== name ? "opacity-40" : "opacity-100",
				)}
				aria-hidden={true}
			/>
			<p
				className={cx(
					// base
					"font-repro text-overline-2xs truncate whitespace-nowrap uppercase",
					//  active text color
					activeLegend && activeLegend !== name ? "opacity-40" : "opacity-100",
				)}
			>
				{name}
			</p>
		</li>
	);
};

interface ScrollButtonProps {
	icon: ElementType;
	onClick?: () => void;
	disabled?: boolean;
}

const ScrollButton = ({ icon, onClick, disabled }: ScrollButtonProps) => {
	const Icon = icon;
	const [isPressed, setIsPressed] = useState(false);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (isPressed) {
			intervalRef.current = setInterval(() => {
				onClick?.();
			}, 300);
		} else {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		}
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [isPressed, onClick]);

	useEffect(() => {
		if (disabled) {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
			setIsPressed(false);
		}
	}, [disabled]);

	return (
		<button
			type="button"
			className={cx(
				// base
				"group inline-flex size-5 items-center truncate rounded transition",
				disabled
					? "cursor-not-allowed text-gray-400 dark:text-gray-600"
					: "cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-50",
			)}
			disabled={disabled}
			onClick={(e) => {
				e.stopPropagation();
				onClick?.();
			}}
			onMouseDown={(e) => {
				e.stopPropagation();
				setIsPressed(true);
			}}
			onMouseUp={(e) => {
				e.stopPropagation();
				setIsPressed(false);
			}}
		>
			<Icon className="size-full" aria-hidden="true" />
		</button>
	);
};

interface LegendProps extends OlHTMLAttributes<HTMLOListElement> {
	categories: string[];
	colors?: AvailableChartColorsKeys[];
	onClickLegendItem?: (category: string, color: string) => void;
	activeLegend?: string;
	enableLegendSlider?: boolean;
}

type HasScrollProps = {
	left: boolean;
	right: boolean;
};

const Legend = forwardRef<HTMLOListElement, LegendProps>((props, ref) => {
	const {
		categories,
		colors = AvailableChartColors,
		className,
		onClickLegendItem,
		activeLegend,
		enableLegendSlider = false,
		...other
	} = props;
	const scrollableRef = useRef<HTMLInputElement>(null);
	const scrollButtonsRef = useRef<HTMLDivElement>(null);
	const [hasScroll, setHasScroll] = useState<HasScrollProps | null>(null);
	const [isKeyDowned, setIsKeyDowned] = useState<string | null>(null);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	const checkScroll = useCallback(() => {
		const scrollable = scrollableRef?.current;
		if (!scrollable) return;

		const hasLeftScroll = scrollable.scrollLeft > 0;
		const hasRightScroll =
			scrollable.scrollWidth - scrollable.clientWidth > scrollable.scrollLeft;

		setHasScroll({ left: hasLeftScroll, right: hasRightScroll });
	}, [setHasScroll]);

	const scrollToTest = useCallback(
		(direction: "left" | "right") => {
			const element = scrollableRef?.current;
			const scrollButtons = scrollButtonsRef?.current;
			const scrollButtonsWith = scrollButtons?.clientWidth ?? 0;
			const width = element?.clientWidth ?? 0;

			if (element && enableLegendSlider) {
				element.scrollTo({
					left:
						direction === "left"
							? element.scrollLeft - width + scrollButtonsWith
							: element.scrollLeft + width - scrollButtonsWith,
					behavior: "smooth",
				});
				setTimeout(() => {
					checkScroll();
				}, 400);
			}
		},
		[enableLegendSlider, checkScroll],
	);

	useEffect(() => {
		const keyDownHandler = (key: string) => {
			if (key === "ArrowLeft") {
				scrollToTest("left");
			} else if (key === "ArrowRight") {
				scrollToTest("right");
			}
		};
		if (isKeyDowned) {
			keyDownHandler(isKeyDowned);
			intervalRef.current = setInterval(() => {
				keyDownHandler(isKeyDowned);
			}, 300);
		} else {
			clearInterval(intervalRef.current as NodeJS.Timeout);
		}
		return () => clearInterval(intervalRef.current as NodeJS.Timeout);
	}, [isKeyDowned, scrollToTest]);

	const keyDown = (e: KeyboardEvent) => {
		e.stopPropagation();
		if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
			e.preventDefault();
			setIsKeyDowned(e.key);
		}
	};
	const keyUp = (e: KeyboardEvent) => {
		e.stopPropagation();
		setIsKeyDowned(null);
	};

	useEffect(() => {
		const scrollable = scrollableRef?.current;
		if (enableLegendSlider) {
			checkScroll();
			scrollable?.addEventListener("keydown", keyDown);
			scrollable?.addEventListener("keyup", keyUp);
		}

		return () => {
			scrollable?.removeEventListener("keydown", keyDown);
			scrollable?.removeEventListener("keyup", keyUp);
		};
	}, [checkScroll, enableLegendSlider]);

	return (
		<ol
			ref={ref}
			className={cx("relative overflow-hidden", className)}
			{...other}
		>
			<div
				ref={scrollableRef}
				tabIndex={0}
				className={cx(
					"flex h-full gap-4",
					enableLegendSlider
						? hasScroll?.right || hasScroll?.left
							? "snap-mandatory items-center overflow-auto pl-4 pr-12 [scrollbar-width:none] [&::-webkit-scrollbar]:hidden"
							: ""
						: "flex-wrap",
				)}
			>
				{categories.map((category, index) => (
					<LegendItem
						key={`item-${index}`}
						name={category}
						color={colors[index] as AvailableChartColorsKeys}
						onClick={onClickLegendItem}
						activeLegend={activeLegend}
					/>
				))}
			</div>
			{enableLegendSlider && (hasScroll?.right || hasScroll?.left) ? (
				<>
					<div
						className={cx(
							// base
							"absolute bottom-0 right-0 top-0 flex h-full items-center justify-center pr-1",
							// background color
							"bg-white dark:bg-gray-950",
						)}
					>
						<ScrollButton
							icon={ArrowLeft}
							onClick={() => {
								setIsKeyDowned(null);
								scrollToTest("left");
							}}
							disabled={!hasScroll?.left}
						/>
						<ScrollButton
							icon={ArrowRight}
							onClick={() => {
								setIsKeyDowned(null);
								scrollToTest("right");
							}}
							disabled={!hasScroll?.right}
						/>
					</div>
				</>
			) : null}
		</ol>
	);
});

Legend.displayName = "Legend";

const ChartLegend = (
	{ payload }: any,
	categoryColors: Map<string, AvailableChartColorsKeys>,
	setLegendHeight: Dispatch<SetStateAction<number>>,
	activeLegend: string | undefined,
	onClick?: (category: string, color: string) => void,
	enableLegendSlider?: boolean,
	legendPosition?: "left" | "center" | "right",
	yAxisWidth?: number,
) => {
	const legendRef = useRef<HTMLDivElement>(null);

	useOnWindowResize(() => {
		const calculateHeight = (height: number | undefined) =>
			height ? Number(height) + 15 : 60;
		setLegendHeight(calculateHeight(legendRef.current?.clientHeight));
	});

	const legendPayload = payload.filter((item: any) => item.type !== "none");

	const paddingLeft =
		legendPosition === "left" && yAxisWidth ? yAxisWidth - 8 : 0;

	return (
		<div
			ref={legendRef}
			style={{ paddingLeft: paddingLeft }}
			className={cx(
				"flex items-center",
				{ "justify-center": legendPosition === "center" },
				{ "justify-start": legendPosition === "left" },
				{ "justify-end": legendPosition === "right" },
			)}
		>
			<Legend
				categories={legendPayload.map((entry: any) => entry.value)}
				colors={legendPayload.map((entry: any) =>
					categoryColors.get(entry.value),
				)}
				onClickLegendItem={onClick}
				activeLegend={activeLegend}
				enableLegendSlider={enableLegendSlider}
			/>
		</div>
	);
};

//#region Tooltip

type TooltipProps = Pick<ChartTooltipProps, "active" | "payload" | "label">;

type PayloadItem = {
	category: string;
	value: number;
	index: string;
	color: AvailableChartColorsKeys;
	type?: string;
	payload: any;
};

interface ChartTooltipProps {
	active: boolean | undefined;
	payload: PayloadItem[];
	label: string;
	valueFormatter: (value: number) => string;
}

const ChartTooltip = ({
	active,
	payload,
	label,
	valueFormatter,
}: ChartTooltipProps) => {
	if (active && payload && payload.length) {
		const legendPayload = payload.filter((item: any) => item.type !== "none");
		return (
			<div
				className={cx(
					// base
					"rounded-lg border shadow-md",
					// border color
					"border-stroke-secondary",
					// background color
					"bg-surface-tertiary",
				)}
			>
				<div className={cx("border-b border-inherit px-4 py-2")}>
					<p
						className={cx(
							// base
							"font-repro",
							// text color
							"text-text-primary",
						)}
					>
						{label}
					</p>
				</div>
				<div className={cx("space-y-1 px-4 py-2")}>
					{legendPayload.map(({ value, category, color }, index) => (
						<div
							key={`id-${index}`}
							className="flex items-center justify-between space-x-8"
						>
							<div className="flex items-center space-x-2">
								<span
									aria-hidden="true"
									className={cx(
										"h-4 w-4 shrink-0 rounded-[4px]",
										getColorClassName(color, "bg"),
									)}
								/>
								<p
									className={cx(
										// base
										"font-repro text-paragraph-sm whitespace-nowrap text-right",
										// text color
										"text-text-secondary",
									)}
								>
									{category}
								</p>
							</div>
							<p
								className={cx(
									// base
									"font-repro text-paragraph-sm whitespace-nowrap text-right tabular-nums",
									// text color
									"text-text-primary",
								)}
							>
								{valueFormatter(value)}
							</p>
						</div>
					))}
				</div>
			</div>
		);
	}
	return null;
};

//#region LineChart

interface ActiveDot {
	index?: number;
	dataKey?: string;
}

type BaseEventProps = {
	eventType: "dot" | "category";
	categoryClicked: string;
	[key: string]: number | string;
};

type LineChartEventProps = BaseEventProps | null | undefined;

interface LineChartProps extends HTMLAttributes<HTMLDivElement> {
	data: Record<string, any>[];
	index: string;
	categories: string[];
	colors?: AvailableChartColorsKeys[];
	valueFormatter?: (value: number) => string;
	startEndOnly?: boolean;
	showXAxis?: boolean;
	showYAxis?: boolean;
	showGridLines?: boolean;
	yAxisWidth?: number;
	intervalType?: "preserveStartEnd" | "equidistantPreserveStart";
	showTooltip?: boolean;
	showLegend?: boolean;
	autoMinValue?: boolean;
	minValue?: number;
	maxValue?: number;
	allowDecimals?: boolean;
	onValueChange?: (value: LineChartEventProps) => void;
	enableLegendSlider?: boolean;
	tickGap?: number;
	connectNulls?: boolean;
	xAxisLabel?: string;
	yAxisLabel?: string;
	legendPosition?: "left" | "center" | "right";
	tooltipCallback?: (tooltipCallbackContent: TooltipProps) => void;
	customTooltip?: ComponentType<TooltipProps>;
}

const LineChart = forwardRef<HTMLDivElement, LineChartProps>((props, ref) => {
	const {
		data = [],
		categories = [],
		index,
		colors = AvailableChartColors,
		valueFormatter = (value: number) => value.toString(),
		startEndOnly = false,
		showXAxis = true,
		showYAxis = true,
		showGridLines = true,
		yAxisWidth,
		intervalType = "equidistantPreserveStart",
		showTooltip = true,
		showLegend = true,
		autoMinValue = false,
		minValue,
		maxValue,
		allowDecimals = true,
		connectNulls = false,
		className,
		onValueChange,
		enableLegendSlider = false,
		tickGap = 5,
		xAxisLabel,
		yAxisLabel,
		legendPosition = "right",
		tooltipCallback,
		customTooltip,
		...other
	} = props;
	const CustomTooltip = customTooltip;
	const paddingValue =
		(!showXAxis && !showYAxis) || (startEndOnly && !showYAxis) ? 0 : 20;
	const [legendHeight, setLegendHeight] = useState(60);
	const [activeDot, setActiveDot] = useState<ActiveDot | undefined>(undefined);
	const [activeLegend, setActiveLegend] = useState<string | undefined>(
		undefined,
	);
	const categoryColors = constructCategoryColors(categories, colors);

	const yAxisDomain = getYAxisDomain(autoMinValue, minValue, maxValue);
	const hasOnValueChange = !!onValueChange;
	const prevActiveRef = useRef<boolean | undefined>(undefined);
	const prevLabelRef = useRef<string | undefined>(undefined);

	function onDotClick(
		itemData: any,
		event: React.MouseEvent<SVGCircleElement>,
	) {
		event.stopPropagation();

		if (!hasOnValueChange) return;
		if (
			(itemData.index === activeDot?.index &&
				itemData.dataKey === activeDot?.dataKey) ||
			(hasOnlyOneValueForKey(data, itemData.dataKey) &&
				activeLegend &&
				activeLegend === itemData.dataKey)
		) {
			setActiveLegend(undefined);
			setActiveDot(undefined);
			onValueChange?.(null);
		} else {
			setActiveLegend(itemData.dataKey);
			setActiveDot({
				index: itemData.index,
				dataKey: itemData.dataKey,
			});
			onValueChange?.({
				eventType: "dot",
				categoryClicked: itemData.dataKey,
				...itemData.payload,
			});
		}
	}

	function onCategoryClick(dataKey: string) {
		if (!hasOnValueChange) return;
		if (
			(dataKey === activeLegend && !activeDot) ||
			(hasOnlyOneValueForKey(data, dataKey) &&
				activeDot &&
				activeDot.dataKey === dataKey)
		) {
			setActiveLegend(undefined);
			onValueChange?.(null);
		} else {
			setActiveLegend(dataKey);
			onValueChange?.({
				eventType: "category",
				categoryClicked: dataKey,
			});
		}
		setActiveDot(undefined);
	}

	return (
		<div
			ref={ref}
			className={cx("h-80 w-full", className)}
			tremor-id="tremor-raw"
			{...other}
		>
			<ResponsiveContainer>
				<RechartsLineChart
					data={data}
					onClick={
						hasOnValueChange && (activeLegend || activeDot)
							? () => {
									setActiveDot(undefined);
									setActiveLegend(undefined);
									onValueChange?.(null);
								}
							: undefined
					}
				>
					{showGridLines ? (
						<CartesianGrid
							className="stroke-stroke-tertiary"
							horizontal={true}
							vertical={false}
							strokeDasharray="3 3"
						/>
					) : null}
					<XAxis
						padding={{ left: paddingValue, right: paddingValue }}
						hide={!showXAxis}
						dataKey={index}
						interval={startEndOnly ? "preserveStartEnd" : intervalType}
						tick={{ transform: "translate(0, 6)" }}
						ticks={
							startEndOnly
								? [data[0]?.[index], data[data.length - 1]?.[index]]
								: undefined
						}
						fill=""
						stroke=""
						className={cx(
							// base
							"text-overline-2xs font-repro uppercase",
							// text fill
							"fill-text-secondary",
						)}
						tickLine={false}
						axisLine={false}
						minTickGap={tickGap}
					>
						{xAxisLabel && (
							<Label
								position="insideBottom"
								offset={-20}
								className="fill-text-primary text-overline-2xs font-repro uppercase"
							>
								{xAxisLabel}
							</Label>
						)}
					</XAxis>
					<YAxis
						width={yAxisWidth}
						hide={!showYAxis}
						axisLine={false}
						tickLine={false}
						type="number"
						domain={yAxisDomain as AxisDomain}
						tick={{ transform: "translate(-3, 0)" }}
						fill=""
						stroke=""
						className={cx(
							// base
							"text-overline-2xs font-repro uppercase",
							// text fill
							"fill-text-secondary",
						)}
						tickFormatter={valueFormatter}
						allowDecimals={allowDecimals}
						tickCount={9}
					>
						{yAxisLabel && (
							<Label
								position="insideLeft"
								style={{ textAnchor: "middle" }}
								angle={-90}
								offset={-15}
								className="fill-text-primary text-overline-2xs font-repro uppercase"
							>
								{yAxisLabel}
							</Label>
						)}
					</YAxis>
					<Tooltip
						wrapperStyle={{ outline: "none" }}
						isAnimationActive={true}
						animationDuration={100}
						cursor={{ strokeWidth: 0.5 }}
						offset={20}
						position={{ y: 0 }}
						content={({ active, payload, label }) => {
							const cleanPayload: TooltipProps["payload"] = payload
								? payload.map((item: any) => ({
										category: item.dataKey,
										value: item.value,
										index: item.payload[index],
										color: categoryColors.get(
											item.dataKey,
										) as AvailableChartColorsKeys,
										type: item.type,
										payload: item.payload,
									}))
								: [];

							if (
								tooltipCallback &&
								(active !== prevActiveRef.current ||
									label !== prevLabelRef.current)
							) {
								tooltipCallback({ active, payload: cleanPayload, label });
								prevActiveRef.current = active;
								prevLabelRef.current = label;
							}

							return showTooltip && active ? (
								CustomTooltip ? (
									<CustomTooltip
										active={active}
										payload={cleanPayload}
										label={label}
									/>
								) : (
									<ChartTooltip
										active={active}
										payload={cleanPayload}
										label={label}
										valueFormatter={valueFormatter}
									/>
								)
							) : null;
						}}
					/>

					{showLegend ? (
						<RechartsLegend
							verticalAlign="top"
							height={legendHeight}
							content={({ payload }) =>
								ChartLegend(
									{ payload },
									categoryColors,
									setLegendHeight,
									activeLegend,
									hasOnValueChange
										? (clickedLegendItem: string) =>
												onCategoryClick(clickedLegendItem)
										: undefined,
									enableLegendSlider,
									legendPosition,
									yAxisWidth,
								)
							}
						/>
					) : null}
					{categories.map((category) => (
						<Line
							className={cx(
								getColorClassName(
									categoryColors.get(category) as AvailableChartColorsKeys,
									"stroke",
								),
							)}
							strokeOpacity={
								activeDot || (activeLegend && activeLegend !== category)
									? 0.3
									: 1
							}
							activeDot={(props: any) => {
								const {
									cx: cxCoord,
									cy: cyCoord,
									stroke,
									strokeLinecap,
									strokeLinejoin,
									strokeWidth,
									dataKey,
								} = props;
								return (
									<Dot
										className={cx(
											"stroke-surface-primary",
											onValueChange ? "cursor-pointer" : "",
											getColorClassName(
												categoryColors.get(dataKey) as AvailableChartColorsKeys,
												"fill",
											),
										)}
										cx={cxCoord}
										cy={cyCoord}
										r={5}
										fill=""
										stroke={stroke}
										strokeLinecap={strokeLinecap}
										strokeLinejoin={strokeLinejoin}
										strokeWidth={2}
										onClick={(_, event) => onDotClick(props, event)}
									/>
								);
							}}
							dot={(props: any) => {
								const {
									stroke,
									strokeLinecap,
									strokeLinejoin,
									strokeWidth,
									cx: cxCoord,
									cy: cyCoord,
									dataKey,
									index,
								} = props;

								if (
									(hasOnlyOneValueForKey(data, category) &&
										!(
											activeDot ||
											(activeLegend && activeLegend !== category)
										)) ||
									(activeDot?.index === index &&
										activeDot?.dataKey === category)
								) {
									return (
										<Dot
											key={index}
											cx={cxCoord}
											cy={cyCoord}
											r={5}
											stroke={stroke}
											fill=""
											strokeLinecap={strokeLinecap}
											strokeLinejoin={strokeLinejoin}
											strokeWidth={2}
											className={cx(
												"stroke-surface-primary",
												onValueChange ? "cursor-pointer" : "",
												getColorClassName(
													categoryColors.get(
														dataKey,
													) as AvailableChartColorsKeys,
													"fill",
												),
											)}
										/>
									);
								}
								return <Fragment key={index}></Fragment>;
							}}
							key={category}
							name={category}
							type="linear"
							dataKey={category}
							stroke=""
							strokeWidth={2}
							strokeLinejoin="round"
							strokeLinecap="round"
							isAnimationActive={false}
							connectNulls={connectNulls}
						/>
					))}
					{/* hidden lines to increase clickable target area */}
					{onValueChange
						? categories.map((category) => (
								<Line
									className={cx("cursor-pointer")}
									strokeOpacity={0}
									key={category}
									name={category}
									type="linear"
									dataKey={category}
									stroke="transparent"
									fill="transparent"
									legendType="none"
									tooltipType="none"
									strokeWidth={12}
									connectNulls={connectNulls}
									onClick={(props: any, event) => {
										event.stopPropagation();
										const { name } = props;
										onCategoryClick(name);
									}}
								/>
							))
						: null}
				</RechartsLineChart>
			</ResponsiveContainer>
		</div>
	);
});

LineChart.displayName = "LineChart";

export { LineChart, type LineChartEventProps, type TooltipProps };
