import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { cx } from "../classnames";
import {
	Root as DropdownMenuPrimitiveRoot,
	Portal as DropdownMenuPrimitivePortal,
	Trigger as DropdownMenuPrimitiveTrigger,
	Content as DropdownMenuPrimitiveContent,
	Item as DropdownMenuPrimitiveItem,
	Separator as DropdownMenuPrimitiveSeparator,
} from "@radix-ui/react-dropdown-menu";

/**
 * IMPORTANT NOTE:
 * - There are more dropdown components that we can add here as required. For ex, dropdown groups,
 * 	 seperators, labels, sub-content menus, etc.
 */

/**
 * DropdownMenu
 * - Dropdown component to wrap all dropdown primitives.
 */
const DropdownMenu = DropdownMenuPrimitiveRoot;

/**
 * DropdownMenuTrigger
 * - Represents the trigger that will open the dropdown. Any component can be passed as a child to this trigger (note that the
 * 	 trigger will handle the click state). If you want to bypass the trigger click state, i.e. the trigger is a child Button,
 * 	 set the DropdownMenuTrigger prop "asChild" to true.
 */
const DropdownMenuTrigger = DropdownMenuPrimitiveTrigger;

/**
 * DropdownMenuPortal
 * - Represents the outer state for a dropdown. This is used to handle "click off" state. For most use cases, you will not need this
 * 	 component as it is automatically included in DropdownMenuContent.
 */
const DropdownMenuPortal = DropdownMenuPrimitivePortal;

/**
 * DropdownMenuContent
 * - This is the actual dropdown menu that will be rendered, all items such as DropdownMenuItem should be rendered within this component.
 */
const DropdownMenuContent = forwardRef<
	ElementRef<typeof DropdownMenuPrimitiveContent>,
	ComponentPropsWithoutRef<typeof DropdownMenuPrimitiveContent>
>(({ className, sideOffset = 4, ...props }, ref) => (
	<DropdownMenuPrimitivePortal>
		<DropdownMenuPrimitiveContent
			ref={ref}
			sideOffset={sideOffset}
			className={cx(
				"bg-surface-secondary text-text-secondary border-stroke-secondary z-50 min-w-[8rem] overflow-hidden rounded-lg border p-1 shadow-md",
				"data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 focus:ring-0 focus-visible:outline-0",
				className,
			)}
			{...props}
		/>
	</DropdownMenuPrimitivePortal>
));
DropdownMenuContent.displayName = DropdownMenuPrimitiveContent.displayName;

import { LucideIcon } from "lucide-react";

/**
 * DropdownMenuItem
 * - Represents a single item that will be rendered within the dropdown. Any children can be passed here and the only schematization is for a
 * 	 leading icon which is one of the most common dropdown use cases we have.
 */
const DropdownMenuItem = forwardRef<
	ElementRef<typeof DropdownMenuPrimitiveItem>,
	ComponentPropsWithoutRef<typeof DropdownMenuPrimitiveItem> & {
		inset?: boolean;
		LeadingIcon?: LucideIcon;
	}
>(({ className, inset, LeadingIcon, ...props }, ref) => (
	<DropdownMenuPrimitiveItem
		ref={ref}
		className={cx(
			"font-repro hover:bg-surface-hover-1 hover:text-text-primary focus:bg-surface-hover-1 focus:text-text-primary text-text-secondary group relative flex cursor-pointer select-none flex-row items-center gap-2 rounded-md px-2 py-1.5 text-sm font-[350] outline-none transition-colors focus:outline-0 focus:ring-0 focus-visible:outline-0 focus-visible:ring-0 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&>svg]:size-4 [&>svg]:shrink-0",
			inset && "pl-8",
			className,
		)}
		{...props}
	>
		{LeadingIcon && <LeadingIcon className="text-text-placeholder h-4 w-4" />}
		{props.children}
	</DropdownMenuPrimitiveItem>
));
DropdownMenuItem.displayName = DropdownMenuPrimitiveItem.displayName;

/**
 * DropdownMenuSeparator
 * - Represents a horizontal line in a dropdown menu.
 */
const DropdownMenuSeparator = forwardRef<
	React.ElementRef<typeof DropdownMenuPrimitiveSeparator>,
	React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitiveSeparator>
>(({ className, ...props }, ref) => (
	<DropdownMenuPrimitiveSeparator
		ref={ref}
		className={cx("bg-stroke-tertiary -mx-1 my-1 h-px", className)}
		{...props}
	/>
));
DropdownMenuSeparator.displayName = DropdownMenuPrimitiveSeparator.displayName;

export {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSeparator,
};
